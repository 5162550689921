export enum FullAppointmentStatus {
    WAITING_FOR_THE_TIMING,
    WAITING_FOR_INTAKE,
    CLOSED_SUCCESSFULLY,
    CLOSED_UNSUCCESSFULLY,
    CLOSED_PAYMENT_OVERDUE,
    WAITING_FOR_PAYMENT,
    CONFIRMED_AWAITING_FULL_PAYMENT,
    RESCHEDULED_AWAITING_FULL_PAYMENT,
    CONFIRMED_WAITING_FOR_A_MEETING,
    RESCHEDULED_AWAITING_APPOINTMENT,
    WAITING_FOR_CONFIRMATION,
    FORMATION_OF_DOCUMENTS,
    APPOINTMENT_EXPIRED,
    COPAY_AWAITING_PAYMENT,
    NEED_APPOINTMENT,
    // only ui statuses
    NOT_ELIGIBLE,
    AWAITING_INSURANCE_VERIFICATION,
    NEED_APPOINTMENT_WITHOUT_COPAY,
    PHARMACY_ISSUE,
    WAITING_FOR_PAYMENT_OR_PENDING_BY_DOCTOR,
    INTAKE_VERIFICATION,
    ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR,
    ERX_IN_PROGRESS_EXECUTED_BY_MANAGER,
    AWAITING_MEDICATION,
    UNKNOWN_STATUS,
}

export interface NotificationSettings {
    message: string;
    actions?: NotificationAction[];
    isNotificationWithoutTask?: boolean;
    buttonText?: string;
    title?: string;
}

export interface NotificationAction {
    text: string;
    action: NotificationActionTypes;
}

export enum NotificationActionTypes {
    GO_TO_INSURANCE = 'insurance',
    GO_TO_APPOINTMENT = 'appointment',
}

export interface NotificationSettingsList {
    [key: string]: NotificationSettings;
}

export enum FormatNotificationMessageConstants {
    DATE_AND_TIME = '$DateAndTime',
    USER_NAME = '$UserName',
    INSURANCE_PROVIDER = '$InsuranceProvider',
    DATE_AND_TIME_PLUS_15_MINS = '$DateAndTimePlus15Mins',
    SUPPORT_NUMBER = '$SupportNumber',
    DOCTOR_NAME = '$DoctorName',
    SERVICE_PLAN = '$ServicePlan',
}

export const NOTIFICATION_SETTINGS: NotificationSettingsList = {
    [FullAppointmentStatus.WAITING_FOR_THE_TIMING]: {
        message:
            'Thank you for choosing MEDvidi for your mental health care. Please select the date and time for your online appointment.',
        buttonText: 'Complete booking',
    },
    [FullAppointmentStatus.WAITING_FOR_INTAKE]: {
        message:
            'Your intake form is incomplete.</br> Please finish it in your account to secure your appointment. Your swift response is crucial.',
        buttonText: 'Fill out intake',
    },
    [FullAppointmentStatus.CLOSED_SUCCESSFULLY]: {
        message:
            'Your appointment has finished successfully. Please select the convenient date and time for your follow-up visit from the available options.',
        buttonText: 'Book appointment',
    },
    [FullAppointmentStatus.CLOSED_UNSUCCESSFULLY]: {
        message:
            'We are sorry that the appointment did not fulfill your expectations. We will work on improving the quality of our services. Please proceed for your next booking.',
        buttonText: 'Book appointment',
    },
    [FullAppointmentStatus.CLOSED_PAYMENT_OVERDUE]: {
        message:
            'Sorry, the payment is now overdue, and the appointment information was canceled because of that. To book a visit, please select the date and time and complete the payment.',
        buttonText: 'Book appointment',
    },
    [FullAppointmentStatus.WAITING_FOR_PAYMENT]: {
        message: 'Almost done! Please complete the payment to confirm the appointment.',
        buttonText: 'Complete booking',
    },
    [FullAppointmentStatus.CONFIRMED_AWAITING_FULL_PAYMENT]: {
        message:
            'The information about the preferred date and time was saved. Please complete the payment to confirm your appointment.',
        buttonText: 'Complete booking',
    },
    [FullAppointmentStatus.RESCHEDULED_AWAITING_FULL_PAYMENT]: {
        message:
            'The information about the date and time of your online visit was updated. Please complete the payment to confirm your appointment.',
        buttonText: 'Complete booking',
    },
    [FullAppointmentStatus.CONFIRMED_WAITING_FOR_A_MEETING]: {
        message: `The "Join" button will become active right before your appointment. You will also receive a link to video conference 24 hours and 1 hour before the appointment via email and SMS.`,
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.RESCHEDULED_AWAITING_APPOINTMENT]: {
        message: `The time of your online visit was changed successfully to ${FormatNotificationMessageConstants.DATE_AND_TIME}. Thank you for choosing MEDvidi for your mental health care.`,
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.WAITING_FOR_CONFIRMATION]: {
        message: `Dear ${FormatNotificationMessageConstants.USER_NAME}, verification has been cleared and your appointment can now be scheduled.
        Our Care Team will reach out shortly to confirm the time and date. We will do our best to find time that best fits your schedule.`,
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.FORMATION_OF_DOCUMENTS]: {
        message: `We are working on your request for the appointment letter. You will be notified once it is ready. Please proceed for your next booking.`,
        buttonText: 'Book appointment',
    },
    [FullAppointmentStatus.COPAY_AWAITING_PAYMENT]: {
        message: `Dear ${FormatNotificationMessageConstants.USER_NAME}, Thank you for choosing us as a mental health clinic. Based on your eligibility check, we ask you to pay the copay.`,
        buttonText: 'Complete booking',
    },
    [FullAppointmentStatus.AWAITING_INSURANCE_VERIFICATION]: {
        message: `Dear ${FormatNotificationMessageConstants.USER_NAME}. Thank you for submitting the insurance details.
        We are waiting for a response from your ${FormatNotificationMessageConstants.INSURANCE_PROVIDER}.
        After receiving the results we will notify you of the next steps.`,
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.NEED_APPOINTMENT_WITHOUT_COPAY]: {
        message: `Dear ${FormatNotificationMessageConstants.USER_NAME},
        your eligibility for a mental health appointment with MEDvidi was just confirmed by your insurance provider.
        Our Care Team will contact you shortly for further steps.`,
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.NOT_ELIGIBLE]: {
        message: `Unfortunately, your insurance is not eligible. Would you like to update your insurance information?`,
        actions: [
            { text: 'Yes, Update', action: NotificationActionTypes.GO_TO_INSURANCE },
            {
                text: "I'll proceed with full payment",
                action: NotificationActionTypes.GO_TO_APPOINTMENT,
            },
        ],
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.NEED_APPOINTMENT]: {
        message: `Dear ${FormatNotificationMessageConstants.USER_NAME}.
        Thank you for your payment!
        A representative will be contacting you shortly by calling the number provided on your application to schedule and/or confirm the date and time of your appointment, verify your information and inform if any additional documents are needed.`,
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.PHARMACY_ISSUE]: {
        message: `Dear ${FormatNotificationMessageConstants.USER_NAME}, we are working on your problem with a pharmacy. Thank you for your patience.
        Please select the convenient date and time for your next visit from the available options.`,
        buttonText: 'Book Appointment',
    },
    [FullAppointmentStatus.WAITING_FOR_PAYMENT_OR_PENDING_BY_DOCTOR]: {
        title: 'Next Step: Confirm Follow-Up',
        message: `Your provider ${FormatNotificationMessageConstants.DOCTOR_NAME} has scheduled a follow up visit for you to continue ${FormatNotificationMessageConstants.SERVICE_PLAN}. <br> <br>
            Please confirm your appointment below to secure your booking slot. You can reschedule the date & time post-payment.`,
        buttonText: 'Confirm now',
    },
    [FullAppointmentStatus.INTAKE_VERIFICATION]: {
        message: `Dear ${FormatNotificationMessageConstants.USER_NAME}, your intake and PDMP forms are now under review!
        This process helps us ensure you are receiving the best possible treatment tailored for your needs.`,
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.AWAITING_MEDICATION]: {
        title: 'Prescription ready for pickup',
        message: `Due to nation-wide medication shortages, we recommend calling your pharmacy before visiting to
        ensure your medication is ready for pickup. This can save you time and help avoid any unnecessary trips.
        For any assistance, contact us at
        <a href="tel:${FormatNotificationMessageConstants.SUPPORT_NUMBER}" target="_blank">${FormatNotificationMessageConstants.SUPPORT_NUMBER}</a>`,
        buttonText: 'See prescription',
    },
    [FullAppointmentStatus.ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR]: {
        title: 'Appointment completed',
        message: `We're sending your prescription to the pharmacy right now.
        You'll get an update as soon as your meds are ready for pick-up.`,
        isNotificationWithoutTask: true,
    },
    [FullAppointmentStatus.ERX_IN_PROGRESS_EXECUTED_BY_MANAGER]: {
        title: 'Appointment completed',
        message: `We're sending your prescription to the pharmacy right now.
        You'll get an update as soon as your meds are ready for pick-up.`,
        isNotificationWithoutTask: true,
    },
};
