import { Injectable } from '@angular/core';
import {
    EligibilityResultsStatus,
    IApiLeadServiceLead,
    IEligibilityResultsResponse,
    LeadCreationType,
} from '../../interfaces';
import { FullAppointmentStatus } from '../../settings/appointment-notifications-settings';
import {
    EApiLeadServiceStatus,
    SUCCESSFUL_CLOSE_STATUSES,
} from '../../interfaces/appointment-statuses';
import {
    EDashboardPageTaskType,
    IDashboardPageTask,
} from '../../pages/dashboard/dashboard-task.interface';
import { ServiceCodeEnum } from '@patient/app/shared/enums/service-code.enum';

@Injectable({ providedIn: 'root' })
export class LeadService {
    public generateTaskForLeads(leads: IApiLeadServiceLead[]): IApiLeadServiceLead[] {
        let tasks: IDashboardPageTask[] = [];

        const updatedLeads = leads;

        for (const lead of leads) {
            if (!lead.service?.code) continue;

            let taskType: EDashboardPageTaskType;
            const serviceCode: string = lead.service.code;

            const {
                FOLLOW_UP_VISIT,
                VISIT,
                TIME,
                PAYMENT,
                SECOND_PAYMENT,
                INTAKE,
                NONE,
                PRESCRIPTIONS,
            } = EDashboardPageTaskType;

            switch (lead.status) {
                case EApiLeadServiceStatus.NEW:
                case EApiLeadServiceStatus.SERVICE_CHOSEN:
                case EApiLeadServiceStatus.APPLICATION:
                case EApiLeadServiceStatus.PAYMENT:
                case EApiLeadServiceStatus.PENDING:
                case EApiLeadServiceStatus.DOC_UNAVAILABLE:
                    taskType = !lead?.time ? TIME : PAYMENT;
                    break;
                case EApiLeadServiceStatus.WAITING_FOR_INTAKE:
                    taskType = INTAKE;
                    break;
                case EApiLeadServiceStatus.APPROVED:
                case EApiLeadServiceStatus.NEED_LETTER:
                case EApiLeadServiceStatus.CLOSED_WON:
                case EApiLeadServiceStatus.PHARMACY_ISSUE:
                    taskType = FOLLOW_UP_VISIT;
                    break;
                case EApiLeadServiceStatus.CLOSED_LOST:
                case EApiLeadServiceStatus.CLOSED_TEST:
                case EApiLeadServiceStatus.CLOSED_REFUNDED:
                case EApiLeadServiceStatus.REFUND:
                case EApiLeadServiceStatus.CLOSED_UNPAID:
                    taskType = VISIT;
                    break;
                case EApiLeadServiceStatus.WAITING_FOR_FULL_PAYMENT:
                    taskType = SECOND_PAYMENT;
                    break;
                case EApiLeadServiceStatus.AWAITING_MEDICATION:
                    taskType = PRESCRIPTIONS;
                    break;
                default:
                    taskType = NONE;
                    break;
            }

            if (!taskType) continue;

            const visitTaskIndexForCurrentService = tasks.findIndex(
                (task) =>
                    [FOLLOW_UP_VISIT, VISIT].includes(task.type) &&
                    task.service.code === serviceCode
            );

            const notVisitTaskIndexForCurrentService = tasks.findIndex(
                (task) =>
                    ![FOLLOW_UP_VISIT, VISIT].includes(task.type) &&
                    task.service.code === serviceCode
            );

            if (![FOLLOW_UP_VISIT, VISIT].includes(taskType)) {
                if (visitTaskIndexForCurrentService > -1) {
                    tasks.splice(visitTaskIndexForCurrentService, 1);
                }

                tasks.push(this.createTaskObject(lead, taskType, serviceCode));
            } else if (notVisitTaskIndexForCurrentService < 0) {
                if (
                    visitTaskIndexForCurrentService > -1 &&
                    taskType === FOLLOW_UP_VISIT &&
                    tasks[visitTaskIndexForCurrentService].type === VISIT
                ) {
                    tasks.splice(visitTaskIndexForCurrentService, 1);
                    tasks.push(this.createTaskObject(lead, taskType, serviceCode));
                } else {
                    tasks.push(this.createTaskObject(lead, taskType, serviceCode));
                }
            }
        }

        tasks = tasks.filter((t) => t.type !== EDashboardPageTaskType.NONE);
        updatedLeads.forEach((lead) => (lead.task = tasks.find((task) => task.leadId === lead.id)));
        return updatedLeads;
    }

    private createTaskObject(
        lead: IApiLeadServiceLead,
        taskType: EDashboardPageTaskType,
        serviceCode: string
    ): IDashboardPageTask {
        return {
            service: { code: serviceCode, name: lead.service.name },
            type: taskType,
            peerId: lead.pearId ?? undefined,
            applicationUUID: lead.applicationUuid ?? undefined,
            leadId: lead.id,
            prescriptionDosespotIds: lead.prescriptionDosespotIds,
        };
    }

    public isDraftLead(lead: IApiLeadServiceLead): boolean {
        return lead.service.code === ServiceCodeEnum.SYSTEM_SERVICE;
    }

    public getActualLeadEligibilityResult(
        lead: IApiLeadServiceLead
    ): IEligibilityResultsResponse | null {
        const sortedResults = lead?.leadEligibilityResults?.sort(
            (a, b) => new Date(b.updatedAt).valueOf() - new Date(a.updatedAt).valueOf()
        );
        return sortedResults ? sortedResults[0] : null;
    }

    public getFullStatus(lead: IApiLeadServiceLead): FullAppointmentStatus {
        switch (lead?.status) {
            // PENDING
            case EApiLeadServiceStatus.PENDING:
                return FullAppointmentStatus.WAITING_FOR_PAYMENT_OR_PENDING_BY_DOCTOR;
            // PAYMENT
            case EApiLeadServiceStatus.PAYMENT:
                const { result, coPayAmount } = this.getActualLeadEligibilityResult(lead) || {};
                const isCopay =
                    result === EligibilityResultsStatus.ELIGIBLE_WITH_CO_PAY && coPayAmount;

                if (isCopay) {
                    return FullAppointmentStatus.COPAY_AWAITING_PAYMENT;
                } else if (!lead?.time) {
                    return FullAppointmentStatus.WAITING_FOR_THE_TIMING;
                } else if (lead?.creationType === LeadCreationType.BY_DOCTOR) {
                    return FullAppointmentStatus.WAITING_FOR_PAYMENT_OR_PENDING_BY_DOCTOR;
                } else {
                    return FullAppointmentStatus.WAITING_FOR_PAYMENT;
                }
            // NEW
            case EApiLeadServiceStatus.NEW:
                const eligibilityResults = this.getActualLeadEligibilityResult(lead);
                const isNotEligible =
                    eligibilityResults?.result === EligibilityResultsStatus.NOT_ELIGIBLE;
                if (isNotEligible) {
                    return FullAppointmentStatus.NOT_ELIGIBLE;
                } else if (!lead.time) {
                    return FullAppointmentStatus.WAITING_FOR_THE_TIMING;
                } else {
                    return FullAppointmentStatus.WAITING_FOR_PAYMENT;
                }
            // SERVICE CHOSEN - APPLICATION - DOC UNAVAILABLE
            case EApiLeadServiceStatus.SERVICE_CHOSEN:
            case EApiLeadServiceStatus.APPLICATION:
            case EApiLeadServiceStatus.DOC_UNAVAILABLE:
                if (!lead?.time) {
                    return FullAppointmentStatus.WAITING_FOR_THE_TIMING;
                } else {
                    return FullAppointmentStatus.WAITING_FOR_PAYMENT;
                }
            // WAITING FOR INTAKE
            case EApiLeadServiceStatus.WAITING_FOR_INTAKE:
                return FullAppointmentStatus.WAITING_FOR_INTAKE;
            // WAITING FOR VERIFICATION
            case EApiLeadServiceStatus.WAITING_FOR_VERIFICATION:
                const isInsuranceVerification = !!lead.leadInsurance?.available;
                if (isInsuranceVerification) {
                    return FullAppointmentStatus.AWAITING_INSURANCE_VERIFICATION;
                } else {
                    return FullAppointmentStatus.INTAKE_VERIFICATION;
                }
            // NEED APPOINTMENT
            case EApiLeadServiceStatus.NEED_APPOINTMENT:
                const actualResult = this.getActualLeadEligibilityResult(lead);
                const isWithoutCopay =
                    actualResult?.result === EligibilityResultsStatus.ELIGIBLE_WITHOUT_CO_PAY;

                if (isWithoutCopay) {
                    return FullAppointmentStatus.NEED_APPOINTMENT_WITHOUT_COPAY;
                } else {
                    if (lead.leadInsurance?.available) {
                        const isInsuranceWithCopay =
                            actualResult?.result === EligibilityResultsStatus.ELIGIBLE_WITH_CO_PAY;
                        return isInsuranceWithCopay
                            ? FullAppointmentStatus.NEED_APPOINTMENT
                            : FullAppointmentStatus.AWAITING_INSURANCE_VERIFICATION;
                    }

                    return FullAppointmentStatus.WAITING_FOR_CONFIRMATION;
                }
            // WAITING FOR APPOINTMENT
            case EApiLeadServiceStatus.WAITING_FOR_APPOINTMENT:
                if (lead.isRescheduled) {
                    return FullAppointmentStatus.RESCHEDULED_AWAITING_APPOINTMENT;
                } else {
                    return FullAppointmentStatus.CONFIRMED_WAITING_FOR_A_MEETING;
                }
            // APPROVED - CLOSED WON
            case EApiLeadServiceStatus.APPROVED:
            case EApiLeadServiceStatus.CLOSED_WON:
                return FullAppointmentStatus.CLOSED_SUCCESSFULLY;
            // REFUND - CLOSET LOST - CLOSED TEST - CLOSED REFUNDED
            case EApiLeadServiceStatus.REFUND:
            case EApiLeadServiceStatus.CLOSED_LOST:
            case EApiLeadServiceStatus.CLOSED_TEST:
            case EApiLeadServiceStatus.CLOSED_REFUNDED:
                return FullAppointmentStatus.CLOSED_UNSUCCESSFULLY;
            // NEED LETTER
            case EApiLeadServiceStatus.NEED_LETTER:
                return FullAppointmentStatus.FORMATION_OF_DOCUMENTS;
            // APPOINTMENT EXPIRED
            case EApiLeadServiceStatus.APPOINTMENT_EXPIRED:
                return FullAppointmentStatus.APPOINTMENT_EXPIRED;
            // FULL PAYMENT
            case EApiLeadServiceStatus.WAITING_FOR_FULL_PAYMENT:
                if (lead.isRescheduled) {
                    return FullAppointmentStatus.RESCHEDULED_AWAITING_FULL_PAYMENT;
                } else {
                    return FullAppointmentStatus.CONFIRMED_AWAITING_FULL_PAYMENT;
                }
            // CLOSED UNPAID
            case EApiLeadServiceStatus.CLOSED_UNPAID:
                return FullAppointmentStatus.CLOSED_PAYMENT_OVERDUE;
            // pharmacy issue
            case EApiLeadServiceStatus.PHARMACY_ISSUE:
                return FullAppointmentStatus.PHARMACY_ISSUE;
            case EApiLeadServiceStatus.ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR:
                return FullAppointmentStatus.ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR;
            case EApiLeadServiceStatus.ERX_IN_PROGRESS_EXECUTED_BY_MANAGER:
                return FullAppointmentStatus.ERX_IN_PROGRESS_EXECUTED_BY_MANAGER;
            case EApiLeadServiceStatus.AWAITING_MEDICATION:
                return FullAppointmentStatus.AWAITING_MEDICATION;
            default:
                return FullAppointmentStatus.UNKNOWN_STATUS;
        }
    }

    public getLeadsWithService(leads: IApiLeadServiceLead[]): IApiLeadServiceLead[] {
        return leads.filter((lead) => {
            return !(!lead.service && !lead.isDashboard);
        });
    }

    public getServiceIdsOfLastSuccessfulLead(leads: IApiLeadServiceLead[]): number[] {
        let lastSuccessfulLead: IApiLeadServiceLead | null = null;
        let isOnlyOneServiceHasSuccessfulLead: boolean = true;

        for (const lead of leads) {
            if (!SUCCESSFUL_CLOSE_STATUSES.includes(lead.status)) continue;

            if (!lastSuccessfulLead) {
                lastSuccessfulLead = lead;
                continue;
            }

            // if more than one service has a successful lead.
            if (lastSuccessfulLead.service.id !== lead.service.id) {
                isOnlyOneServiceHasSuccessfulLead = false;
                break;
            }
        }

        if (!lastSuccessfulLead || !isOnlyOneServiceHasSuccessfulLead) return [];

        return [lastSuccessfulLead.service, ...lastSuccessfulLead.additionalServices].map(
            (service) => service.id
        );
    }

    public isPaymentStatus = (lead: IApiLeadServiceLead) => {
        return (
            lead.status === EApiLeadServiceStatus.PAYMENT ||
            lead.status === EApiLeadServiceStatus.PENDING ||
            lead.status === EApiLeadServiceStatus.WAITING_FOR_FULL_PAYMENT
        );
    };

    public isOverdueLead(lead: IApiLeadServiceLead): boolean {
        if (!lead.time) return true;

        const isLeadTimeLessThanToday = new Date(lead.time) < new Date();
        const isLeadPaymentStatus = lead.status === EApiLeadServiceStatus.PAYMENT;

        return isLeadTimeLessThanToday && isLeadPaymentStatus;
    }

    public removeLeadTimeIfTimeHasElapsed(leads: IApiLeadServiceLead[]): IApiLeadServiceLead[] {
        return leads.map((lead) => {
            if (!this.isOverdueLead(lead)) return lead;

            lead.time = null;

            return lead;
        });
    }
}
