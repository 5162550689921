import { Injectable } from '@angular/core';
import { RestApiService } from '../rest-api.service';
import { Observable } from 'rxjs';
import { DosespotPharmacy, PharmacySearchParams } from '../intake/intake-api.interface';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class PharmacyApiService {

    constructor(private restApiService: RestApiService) {}

    public searchPharmacy(searchParams: PharmacySearchParams): Observable<{ pharmacies: DosespotPharmacy[] }> {
        let params = new HttpParams();

        for (const [key, value] of Object.entries(searchParams)) {
            params = params.append(key, value);
        }

        return this.restApiService.get('electronic-prescriptions/pharmacy/search', { params });
    }

    public sendUntrustedPharmacySelected(leadId: number): Observable<{}> {
        return this.restApiService.post('electronic-prescriptions/untrusted-pharmacy-selected', {
            leadId
        });
    }
}
