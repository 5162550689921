import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WebsiteTerms } from '../../api/terms/terms-api.interface';
import { GoogleAnalyticService } from '@patient/app/shared/modules/google/google-analytic.service';
import {
    EventTypes,
    EventFormIdType,
    EventSourceType,
    PageTitleAnalyticType,
} from '@patient/app/shared/modules/google/google-analytic.utils';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnChanges {
    @Input() terms!: WebsiteTerms | null;

    public toggleGetInTouchWithUs: boolean = false;
    public toggleMenu: boolean = false;

    constructor(private googleAnalyticService: GoogleAnalyticService) {}

    public WEBSITE_URL: string = 'https://medvidi.com/';
    public WEBSITE_NAME: string = 'MEDvidi.com';

    public ngOnChanges(changes: SimpleChanges): void {
        const termsUrl = changes['terms']?.currentValue?.termsUrl;
        if (termsUrl) this.setCopyrightValue(termsUrl);
    }

    private setCopyrightValue(termsUrl: string): void {
        const regExp = /https?:\/\/([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}){1}/;
        const matchObj = termsUrl.match(regExp);

        if (matchObj && matchObj[0] && matchObj[1]) {
            this.WEBSITE_URL = matchObj[0];
            this.WEBSITE_NAME = matchObj[1];
        }
    }

    public onLinkClicked(): void {
        this.googleAnalyticService.emitEvent(EventTypes.CLICK, {
            form_id: EventFormIdType.UD_REGISTRATION_3_0,
            screen_name: EventSourceType.UD_REGISTRATION_STEP_1,
            page_title: PageTitleAnalyticType.DASHBOARD,
        });
    }
}
