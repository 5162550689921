import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-body',
    templateUrl: './body.component.html',
    styleUrls: ['./body.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BodyComponent {
    @Input() isDashboardLayout: boolean = false;
}
