export enum EMobileOperationSystem {
    ANDROID = 'android',
    IOS = 'ios',
    NOT_MOBILE = 'other',
}

export function isMobileOperationSystem(): boolean {
    return [EMobileOperationSystem.IOS, EMobileOperationSystem.ANDROID].includes(
        getMobileOperatingSystem()
    );
}

function getMobileOperatingSystem(): EMobileOperationSystem {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
        return EMobileOperationSystem.ANDROID;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return EMobileOperationSystem.IOS;
    }

    return EMobileOperationSystem.NOT_MOBILE;
}
