import { Injectable } from '@angular/core';
import {
    IApiUserServiceRequestAuthorizationParams,
    IApiUserServiceRequestAuthorizationResponse,
    IApiUserServiceResendVerificationCodeParams,
    IApiUserServiceResendVerificationCodeResponse,
    IApiUserServiceLoginParams,
    IApiUserServiceLoginResponse,
    IApiUserServiceUpdateParams,
    IApiUserServiceUpdateResponse,
    IApiUserServiceGetDataResponse,
    IApiUserServiceSignUpParams,
    ICreateForWebRequest,
} from '../../interfaces';
import { RestApiService } from '../rest-api.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApiUserService {
    constructor(private restApiService: RestApiService) {}

    public requestAuthorization(
        params: IApiUserServiceRequestAuthorizationParams
    ): Observable<IApiUserServiceRequestAuthorizationResponse> {
        return this.restApiService.post('user/send-code', params);
    }

    public resendVerificationCode(
        params: IApiUserServiceResendVerificationCodeParams
    ): Observable<IApiUserServiceResendVerificationCodeResponse> {
        return this.restApiService.post('user/resend', params);
    }

    public signUpAutoLogin(
        params: IApiUserServiceSignUpParams
    ): Observable<IApiUserServiceLoginResponse> {
        return this.restApiService.post('user/sign-up-auto-login', params);
    }

    public login(params: IApiUserServiceLoginParams): Observable<IApiUserServiceLoginResponse> {
        return this.restApiService.post(`user/login`, params);
    }

    public update(params: IApiUserServiceUpdateParams): Observable<IApiUserServiceUpdateResponse> {
        return this.restApiService.put(`user/update`, params);
    }

    public finishOnboarding(): Observable<IApiUserServiceUpdateResponse> {
        return this.restApiService.post(`user/finish-onboarding`);
    }

    public getUserDataByToken(): Observable<IApiUserServiceGetDataResponse> {
        return this.restApiService.get(`user/get-data`);
    }

    public createForWeb(body: ICreateForWebRequest): Observable<any> {
        return this.restApiService.post(`user/create-for-web`, body);
    }
}
