import { IApiLeadServiceLead } from '@patient/app/interfaces';

export enum EventTypes {
    SCREEN_LOADED = 'screen_loaded',
    FORM_EDITED = 'form_edited',
    CLICK = 'click',
    BUTTON_TAPPED = 'button_tapped',
    FORM_START = 'form_start',
    FORM_SUBMIT = 'form_submit',
    CHECKBOX_TAPPED = 'checkbox_tapped',
    UD_BLOCK_SHOWN = 'ud_block_shown',
    APPOINTMENT_TAPPED = 'appointment_tapped',
    PAGINATOR_TAPPED = 'paginator_tapped',
    PRESCRIPTION_TAPPED = 'prescription_tapped',
    DOWNLOAD_TAPPED = 'download_tapped',
    SERVICE_SELECTED = 'Service_selected',
    PLAN_SELECTED = 'Plan_selected',
    DATETIME_SELECTED = 'Datetime_selected',
    DATETIME_LOADED = 'Datetime_loaded',
    PAYMENT_SELECTED = 'payment_selected',
    PURCHASE = 'purchase',
    PURCHASE_ERROR = 'purchase_error',
    CUSTOMER_EXPERIENCE_VOTING = 'customer_experience_voting',
    APPOINTMENT_STARTED = 'appointment_started',
    ONBOARDING_SHOWN = 'onboarding_shown',
    REQULA_RESPONSE = 'regula_response',
    SYSTEM_ERRORS = 'system_errors',
    ERROR = 'Error',
    TIME_FILL_OUT_ALL_INTAKE_STEPS = 'time_fill_out_all_intake_steps',
    POPUP_SHOWN = 'popup_shown',
    EXPERIENCE_IMPRESSION = 'experience_impression',
}

export enum EventActionsType {
    NEXT_REGISTRATION = 'next_registration',
    SIGN_IN = 'sign_in',
    SIGN_UP = 'sign_up',
    FIRST_NAME_EDITED = 'first_name_edited ',
    LAST_NAME_EDITED = 'last_name_edited ',
    GENDER_EDITED = 'gender_edited',
    DATE_OF_BIRTH_EDITED = 'date_of_birth_edited ',
    EMAIL_EDITED = 'email_edited ',
    PHONE_EDITED = 'phone_edited ',
    ZIP_EDITED = 'zip_edited',
    SCHEDULE_APPOINTMENT = 'schedule_appointment',
    GO_TO_DASHBOARD = 'go_to_dashboard',
    JOIN_APPOINTMENT = 'join_appointment',
    WATCH_GUIDE = 'watch_guide',
    BOOK_APPOINTMENT = 'book_appointment',
    RESCHEDULE_APPOINTMENT = 'reschedule_appointment',
    SHOW_ALL_APPOINTMENTS = 'show_all_appointments',
    SHOW_ALL_PRESCRIPTIONS = 'show_all_prescriptions',
    SHOW_ALL_DOCUMENTS = 'show_all_documents',
    NEXT_STEP = 'next_step',
    CONFIRM = 'confirm',
    PAY = 'pay',
    BACK = 'back',
    GO_BACK = 'go_back',
    FILL_INTAKE = 'fill_intake',
    NEXT_INTAKE = 'next_intake',
    BACK_INTAKE = 'back_intake',
    ADD_INVITATION_TO_CALENDAR = 'add_invitation_to_calendar',
    VIEW_DROPDOWN = 'view_dropdown',
    CLOSE_DROPDOWN = 'close_dropdown',
    OPEN_DASHBOARD = 'open_dashboard',
    VIEW_PRESCRIPTION = 'view_prescription',
    CLOSE_PRESCRIPTION = 'close_prescription',
    NEXT = 'next',
    APPLY = 'apply',
    CANCEL = 'cancel',
    ONBOARDING_CLOSE = 'onboarding_close',
    ONBOARDING_START = 'onboarding_start',
    ONBOARDING_COMPLETED = 'onboarding_completed',
    COMPLETE_BOOKING = 'complete_booking',
    SCREEN_VIEW = 'screen_view',
    CONTINUE = 'continue',
    GO_TO_PAYMENT = 'go_to_payment',
    SCHEDULE_NEXT_APPOINTMENT = 'schedule_next_appt',
    CONFIRM_APPOINTMENT = 'confirm_appointment',
    CHANGE_PROVIDER_LIST = 'change_provider_list',
}

export enum EventSourceType {
    UD_SIGN_IN_STEP_1 = 'ud_sign_in_step_1',
    UD_SIGN_IN_STEP_2 = 'ud_sign_in_step_2',
    UD_SIGN_IN_PHONE = 'ud_sign_in_phone',
    UD_SIGN_IN_EMAIL = 'ud_sign_in_email',
    UD_REGISTRATION_STEP_1 = 'ud_registration_step_1',
    UD_REGISTRATION_STEP_2 = 'ud_registration_step_2',
    UD_REGISTRATION_STEP_3_SUCCESS = 'ud_registration_step_3_success',
    UD_REGISTRATION_STEP_3_ERROR = 'ud_registration_step_3_error',
    UD_DYNAMIC_BLOCK = 'ud_dynamic_block',
    USER_DASHBOARD = 'user_dashboard',
    BOOK_APPOINTMENT_SERVICE = 'book_appointment_service',
    BOOK_APPOINTMENT_PLAN = 'book_appointment_plan',
    BOOK_APPOINTMENT_CALENDAR = 'book_appointment_calendar',
    BOOK_APPOINTMENT_PLAN_TIME = 'book_appointment_plan_time',
    BOOK_APPOINTMENT_PAYMENT = 'book_appointment_payment',
    MANAGE_APPOINTMENT = 'manage_appointment',
    PURCHASE_SUCCESS = 'purchase_success',
    INTAKE_SUCCESS = 'intake_success',
    ALL_PRESCRIPTIONS = 'all_prescriptions',
    ALL_APPOINTMENTS = 'all_appointments',
    ALL_DOCUMENTS = 'all_documents',
    UD_SETTINGS = 'ud_settings',
    BOOK_APPOINTMENT_PAYMENT_POPUP = 'book_appointment_payment_popup',
    PP_RETURNING_VERIFICATION_DOB = 'pp_returning_verification_dob',
    APPOINTMENT_COMPLETED = 'appointment_completed',
}

export enum EventFormIdType {
    UD_REGISTRATION_3_0 = 'ud_registration_3.0',
    UD_SIGN_IN_3_0 = 'ud_sign_in_3.0',
    UD_PROFILE_SETTINGS = 'ud_profile_settings',
    BOOK_APPOINTMENT = 'book_appointment_1.0',
    INTAKE = 'intake',
    APPT_THANK_SCREEN = 'appt_thank_screen',
}

export enum EventCheckboxType {
    SMS_CONTENT = 'sms_consent',
    LEGAL_TERMS = 'legal_terms',
    MY_DOCTOR_LATEST_SLOTS = 'my_doctor_latest_slots',
}

export enum EventDoctorSwitchType {
    INITIAL_VISIT = 'initial visit',
    SELECTED = 'selected',
    DESELECTED = 'deselected',
}

export enum EventResultType {
    SUCCESS = 'success',
    FAILED = 'failed',
    OPT_IN = 'opt_in',
    OPT_OUT = 'opt_out',
}

export enum PageTitleAnalyticType {
    SIGN_UP_INITIAL = 'Registration window - personal info',
    SIGN_UP_VERIFY = 'Registration window - verify your email',
    SIGN_UP_THANK_YOU = 'Registration - thank you page after reg',
    SIGN_IN_INITIAL = 'Sign in page - initial',
    SIGN_IN_EMAIL = 'Sign in page - verify email',
    SIGN_IN_PHONE = 'Sign in page - verify phone',
    DASHBOARD = 'Dashboard',
    APPOINTMENTS = 'Appointments page',
    PRESCRIPTIONS = 'Prescriptions page',
    DOCUMENTS = 'Documents page',
    APPOINTMENT = 'Appointment flow - choose service/plan/date&time',
    PAYMENT = 'Appointment flow - payment step',
    PAYMENT_SUCCESS = 'Appointment flow - successful payment page',
    INTAKE = 'Appointment flow - intake step',
    INTAKE_THANK_YOU = 'Appointment flow - thank you page',
    PROFILE = 'Profile page',
    MANAGE = 'Manage appointment page',
    CANCEL = 'Cancel appointment page',
    UPDATE_ZIP = 'update zip page',
}

export enum EventPaymentMethodType {
    CARD = 'card',
    APPLE_PAY = 'apple_pay',
}

export enum EventErrorTextType {
    DOB_DOESNT_MATCH = 'dob_doesnt_match',
}

export interface EventOptions {
    lead_id?: IApiLeadServiceLead[] | number | undefined;
    client_id?: number;
    form_id?: EventFormIdType;
    screen_name?: EventSourceType | string;
    action?: EventActionsType | EventActionsType[];
    result?: EventResultType;
    doctor_id?: number | string;
    checkbox_type?: EventCheckboxType;
    service_type?: string | undefined;
    selection_type?: number;
    appointment_type?: string | undefined;
    appointment_price?: number | undefined;
    total_price?: number | undefined;
    date?: number | undefined;
    time?: string | string[] | undefined | Record<number, string[]>[];
    promocode_applied?: string | undefined;
    contact_method?: string[] | undefined;
    conditions?: string[] | undefined;
    reason?: string | undefined;
    prescription_status?: string;
    appointment_status?: string;
    document_type?: string;
    type?: string | number;
    page_number?: number;
    switcher_condition?: EventDoctorSwitchType;
    ecommerce?: unknown;
    page_title?: string;
    user_time?: string;
    number_of_slots?: number | Record<number, number>[];
    onboarding_page?: number;
    onboarding_version?: string;
    exp_variant_string?: string;
    error_text?: EventErrorTextType;
    payment_method?: EventPaymentMethodType;
    plan?: string;
}
