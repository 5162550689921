<div class="footer">
    <div class="footer-container">
        <div class="footer-columns">
            <!--  Get In Touch With Us  -->
            <div class="footer-columns-item" [class.active]="toggleGetInTouchWithUs">
                <div
                    class="footer-columns-item__header"
                    (click)="toggleGetInTouchWithUs = !toggleGetInTouchWithUs"
                >
                    Get In Touch With Us
                    <mat-icon>expand_more</mat-icon>
                </div>
                <div class="footer-columns-item__links">
                    <a class="link" [href]="'tel:' + terms?.phone" data-test-id="Footer phone link">
                        <mat-icon class="link-icon">smartphone</mat-icon>
                        <span>{{ terms?.phone }}</span>
                    </a>
                    <a
                        class="link"
                        [href]="'mailto:' + terms?.email"
                        data-test-id="Footer email link"
                    >
                        <mat-icon class="link-icon">mail</mat-icon>
                        <span>{{ terms?.email }}</span>
                    </a>
                </div>
            </div>
            <!--  Menu  -->
            <div class="footer-columns-item" [class.active]="toggleMenu">
                <div class="footer-columns-item__header" (click)="toggleMenu = !toggleMenu">
                    Menu
                    <mat-icon>expand_more</mat-icon>
                </div>
                <div class="footer-columns-item__links">
                    <a
                        class="link"
                        (click)="onLinkClicked()"
                        [href]="terms?.termsUrl"
                        target="_blank"
                        data-test-id="Footer terms link"
                    >
                        Terms of service
                    </a>
                    <a
                        class="link"
                        (click)="onLinkClicked()"
                        [href]="terms?.privacyUrl"
                        target="_blank"
                        data-test-id="Footer privacy link"
                    >
                        Privacy policy
                    </a>
                    <a
                        class="link"
                        (click)="onLinkClicked()"
                        [href]="terms?.refundPolicyUrl"
                        target="_blank"
                        data-test-id="Footer refund link"
                    >
                        Refund policy
                    </a>
                </div>
            </div>
        </div>
        <!-- Copyright -->
        <div class="footer-copyright">
            <mat-icon class="footer-copyright__icon">copyright</mat-icon>
            <a class="footer-copyright__link" [href]="WEBSITE_URL" target="_blank">
                {{ WEBSITE_NAME }}
            </a>
        </div>
    </div>
</div>
