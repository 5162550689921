export enum VisitTypeEnum {
    INITIAL = 1,
    FOLLOW_UP = 2,
}

export const enum AppointmentMethodType {
    ONLINE = 1,
    OFFLINE = 2,
}

export interface IApiService {
    id: number;
    code: string;
    name: string;
    visitDuration: number;
    returningVisitDuration: number;
    isMentalHealthGroup: boolean;
}

export interface IApiServicePlan {
    id: number;
    title: string;
    code: string;
    price: number;
    description: string;
    order: string;
    type: VisitTypeEnum;
    appointmentMethod: AppointmentMethodType;
}

export interface IApiServiceItem extends IApiService {
    plans: IApiServicePlan[];
}

export type IApiSchedule = Record<string, string[]>;
