export enum PageTitleEnum {
    SIGN_IN = 'Sign in',
    SIGN_UP = 'Sign up',
    START = 'Start',
    DASHBOARD = 'Dashboard',
    PROFILE = 'Profile',
    BOOKING = 'Booking',
    MANAGE_APPOINTMENT = 'Booking',
    CANCEL_APPOINTMENT = 'Booking',
    PAYMENT = 'Payment',
    INTAKE = 'Intake form',
    INTAKE_THANK_YOU = 'Intake form',
    UPDATE_ZIP = 'Update zip',
    APPOINTMENTS = 'Appointments',
    DOCUMENTS = 'Documents',
    PRESCRIPTIONS = 'Prescriptions',
    VIDEO_CALL = 'Video Call',
    DEFAULT = 'Personal Portal',
}
