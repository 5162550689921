import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { concatMap, from, last, takeWhile } from 'rxjs';
import { AsyncGuard } from '@patient/app/interfaces/async-guard';

type TGuard = new (...services: any) => AsyncGuard;

export const orderedAsyncGuards = (guards: TGuard[]): CanActivateFn => {
  return (route, state) => {
    const guardInstances = guards.map(inject) as AsyncGuard[];

    return from(guardInstances).pipe(
      concatMap((guard) => guard.canActivate(route, state)),
      takeWhile((value) => value === true, true),
      last()
    );
  };
};
