import { AbstractControl, ValidatorFn } from '@angular/forms';

export function NameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: object } | null => {
        const regex = /^[a-z '-]+$/i;

        if ((!control.dirty && !control.touched) || !control.value) return null;

        return regex.test(control.value) ? null : { invalidValue: {} };
    };
}
