import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleAnalyticService } from '@patient/app/shared/modules/google/google-analytic.service';

export interface GoogleAnalyticOptions {
    isAnalyticActive: boolean;
    applicationId: string;
    isAvailableInConsole: boolean;
}

export const GOOGLE_ANALYTIC_OPTIONS = new InjectionToken<GoogleAnalyticOptions>(
    'GOOGLE_ANALYTIC_OPTIONS'
);

function googleAnalyticConfigFactory(
    config: GoogleAnalyticOptions,
    gAnalytic: GoogleAnalyticService
) {
    return config.isAnalyticActive ? () => gAnalytic.initGoogleAnalytic(config) : () => null;
}

@NgModule()
export class GoogleModule {
    public static forRoot(options: GoogleAnalyticOptions): ModuleWithProviders<GoogleModule> {
        const optionProvider = { provide: GOOGLE_ANALYTIC_OPTIONS, useValue: options };
        const analyticProvider = {
            provide: APP_INITIALIZER,
            useFactory: googleAnalyticConfigFactory,
            deps: [GOOGLE_ANALYTIC_OPTIONS, GoogleAnalyticService],
            multi: true,
        };
        return { ngModule: GoogleModule, providers: [optionProvider, analyticProvider] };
    }
}
