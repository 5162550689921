<div class="page-info">
    <mat-icon
        *ngIf="withBackIcon"
        (click)="goBack()"
        [class.visible]="!description"
        class="page-info__back"
    >
        keyboard_backspace
    </mat-icon>
    <div [class.centered]="!description" class="page-info__name" [class.large]="isSignUpPage" [innerHTML]="title"></div>
    <div *ngIf="description" class="page-info__description">{{ description }}</div>
    <div *ngIf="description" class="page-info__offset"></div>
</div>
