const commonSentry = {
  enabled: true,
  tracesSampleRate: 0,
  http: {
    enabled: true,
    defaultErrorHttpMessage: 'Http request failed. Error: {error}. {url}',
  },
};

export const sentryConfigs = {
  adminSentry: {
    ...commonSentry,
    dsn: 'https://ef39a3d9f6af453bb87955faaaefcc90@o1050997.ingest.sentry.io/6522429',
  },
  crmSentry: {
    ...commonSentry,
    dsn: 'https://a1df97bb9f8a4a9980f59ffa50a78e7b@o1050997.ingest.sentry.io/6272915',
    ignoreErrors: [new RegExp(`auth_required`)],
  },
  doctorSentry: {
    ...commonSentry,
    dsn: 'https://abefb119978c46299d6d4fa8f3f90ce9@o1050997.ingest.sentry.io/6070710',
  },
  patientSentry: {
    ...commonSentry,
    dsn: 'https://f3aadf94f9f24aa8a31a164084c72d5a@o1050997.ingest.sentry.io/6461278',
  },
};
