export enum EApiLeadServiceStatus {
    // NEW LEADS (STEP 1 is ALLOWED)
    NEW = 'new',
    SERVICE_CHOSEN = 'service_chosen',
    DOC_UNAVAILABLE = 'docUnavailable',
    PAYMENT = 'payment',
    PENDING = 'pending',
    // ACTIVE LEADS
    NEED_APPOINTMENT = 'paid',
    WAITING_FOR_APPOINTMENT = 'waitingAppointment',
    AWAITING_MEDICATION = 'Awaiting Medication',
    WAITING_FOR_INTAKE = 'waitingIntake',
    WAITING_FOR_VERIFICATION = 'waitingVerification',
    WAITING_FOR_FULL_PAYMENT = 'waitingFullPayment',
    ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR = 'eRxInProgressExecutedByDoctor',
    ERX_IN_PROGRESS_EXECUTED_BY_MANAGER = 'eRxInProgressExecutedByManager',
    APPLICATION = 'application',
    ADDITIONAL_INVOICE = 'additional_invoice',
    // CLOSED LEADS
    PHARMACY_ISSUE = 'pharmacyIssue',
    CLOSED_TEST = 'closed_test',
    CLOSED_LOST = 'closed_lost',
    CLOSED_WON = 'closed_won',
    APPROVED = 'approved',
    CLOSED_UNPAID = 'closedUnpaid',
    CLOSED_REFUNDED = 'closed_refunded',
    REFUND = 'refund',
    UNUSED_APPOINTMENT = 'unusedApt',
    APPOINTMENT_EXPIRED = 'appointment_expired',
    NEED_LETTER = 'need_letter',
}

export enum EAppointmentsStatuses {
    BOOKING = 'Booking',
    CONFIRMATION = 'Confirmation',
    SCHEDULED = 'Scheduled',
    MEDS_PENDING = 'Meds pending',
    COMPLETED = 'Completed',
    CANCELED = 'Canceled'
}

export const APPOINTMENT_STATUSES_LISTS: { [key: string]: EApiLeadServiceStatus[] } = {
    [EAppointmentsStatuses.BOOKING]: [
        EApiLeadServiceStatus.NEW,
        EApiLeadServiceStatus.PAYMENT,
        EApiLeadServiceStatus.WAITING_FOR_FULL_PAYMENT,
        EApiLeadServiceStatus.SERVICE_CHOSEN,
        EApiLeadServiceStatus.WAITING_FOR_INTAKE,
        EApiLeadServiceStatus.UNUSED_APPOINTMENT,
    ],
    [EAppointmentsStatuses.CONFIRMATION]: [
        EApiLeadServiceStatus.NEED_APPOINTMENT,
        EApiLeadServiceStatus.WAITING_FOR_VERIFICATION,
        EApiLeadServiceStatus.PENDING
    ],
    [EAppointmentsStatuses.SCHEDULED]: [
        EApiLeadServiceStatus.WAITING_FOR_APPOINTMENT,
    ],
    [EAppointmentsStatuses.MEDS_PENDING]: [
        EApiLeadServiceStatus.PHARMACY_ISSUE,
        EApiLeadServiceStatus.ERX_IN_PROGRESS_EXECUTED_BY_MANAGER,
        EApiLeadServiceStatus.ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR,
        EApiLeadServiceStatus.NEED_LETTER,
        EApiLeadServiceStatus.AWAITING_MEDICATION
    ],
    [EAppointmentsStatuses.COMPLETED]: [
        EApiLeadServiceStatus.CLOSED_WON,
        EApiLeadServiceStatus.APPROVED,
    ],
    [EAppointmentsStatuses.CANCELED]: [
        EApiLeadServiceStatus.REFUND,
        EApiLeadServiceStatus.CLOSED_LOST,
        EApiLeadServiceStatus.CLOSED_TEST,
        EApiLeadServiceStatus.APPOINTMENT_EXPIRED,
        EApiLeadServiceStatus.CLOSED_REFUNDED,
        EApiLeadServiceStatus.CLOSED_UNPAID
    ],
}

export const SUCCESSFUL_CLOSE_STATUSES: EApiLeadServiceStatus[] = [
    EApiLeadServiceStatus.APPROVED,
    EApiLeadServiceStatus.CLOSED_WON,
    EApiLeadServiceStatus.CLOSED_TEST,
    EApiLeadServiceStatus.NEED_LETTER,
    EApiLeadServiceStatus.PHARMACY_ISSUE,
    EApiLeadServiceStatus.ERX_IN_PROGRESS_EXECUTED_BY_MANAGER,
    EApiLeadServiceStatus.ERX_IN_PROGRESS_EXECUTED_BY_DOCTOR,
    EApiLeadServiceStatus.AWAITING_MEDICATION
];
