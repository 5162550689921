import { AbstractControl, ValidatorFn } from '@angular/forms';

export function EmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: object } | null => {
        const regex = /(^[a-zA-Z0-9_.+#-]{2,35})@([a-zA-Z0-9-]{2,35})\.([a-zA-Z]{2,4})$/;
        if (control.dirty || control.touched) {
            return regex.test(control.value) ? null : { email: {}, emailFormat: {} };
        }
        return null;
    };
}
