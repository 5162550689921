import { AbstractControl, ValidatorFn } from '@angular/forms';

export function ZipcodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: object } | null => {
        const regex = /^\d{5}(-\d{4})?$/;
        if (control.dirty || control.touched) {
            return regex.test(control.value) ? null : { zipCode: {} };
        }
        return null;
    };
}
