import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticService } from '@patient/app/shared/modules/google/google-analytic.service';
import {
    EventActionsType,
    EventTypes,
    EventFormIdType,
    EventSourceType,
    PageTitleAnalyticType
} from '@patient/app/shared/modules/google/google-analytic.utils';

@Component({
    selector: 'app-page-info-block',
    templateUrl: './page-info-block.component.html',
    styleUrls: ['./page-info-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageInfoBlockComponent {
    @Input() title: string | null = '';
    @Input() description: string | null = '';
    @Input() withBackIcon: boolean | null = false;

    public get isSignUpPage(): boolean {
        return this.router.url.includes('/signup');
    }

    constructor(
        private googleAnalyticService: GoogleAnalyticService,
        private router: Router
    ) {}

    public goBack(): void {
        switch(this.router.url) {
            case '/profile':
                this.sendAnalyticsOnButtonTap(EventSourceType.UD_SETTINGS, EventFormIdType.UD_PROFILE_SETTINGS);
                break;
            case '/dashboard/appointments':
                this.sendAnalyticsOnButtonTap(EventSourceType.ALL_APPOINTMENTS);
                break;
            case '/dashboard/prescriptions':
                this.sendAnalyticsOnButtonTap(EventSourceType.ALL_PRESCRIPTIONS);
                break;
            case '/dashboard/documents':
                this.sendAnalyticsOnButtonTap(EventSourceType.ALL_DOCUMENTS);
                break;
        }
        void this.router.navigate(['dashboard']);
    }

    private sendAnalyticsOnButtonTap(source: EventSourceType, form_id?: EventFormIdType): void {
        this.googleAnalyticService.emitEvent(EventTypes.BUTTON_TAPPED, {
            screen_name: source,
            action: EventActionsType.BACK,
            // make properties optional
            ...(form_id && { form_id }),
            page_title: PageTitleAnalyticType.DASHBOARD
        });
    }
}
