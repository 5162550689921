<div class="header-avatar-menu">
    <!-- Profile settings -->
    <a
        routerLink="/profile"
        routerLinkActive="active"
        (click)="editProfile.emit()"
        class="header-avatar-menu-item"
        data-test-id="Edit Profile Button"
    >
        <mat-icon class="header-avatar-menu-item__icon">settings</mat-icon>
        <span class="header-avatar-menu-item__title">Edit profile</span>
    </a>
    <!--  Logout  -->
    <div class="header-avatar-menu-item" data-test-id="Sign Out Button" (click)="logout.emit()">
        <mat-icon class="header-avatar-menu-item__icon">logout</mat-icon>
        <span class="header-avatar-menu-item__title">Sign out</span>
    </div>
</div>
