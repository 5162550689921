import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
    selector: 'app-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    standalone: true,
    imports: [MatExpansionModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelComponent {}
