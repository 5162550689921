import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, Observable, throwError } from 'rxjs';

export interface HttpOptions {
    body?: any;
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'arraybuffer' | 'blob';
    withCredentials?: boolean;
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

@Injectable({ providedIn: 'root' })
export class RestApiService {
    private readonly baseUrl: string = environment.patientAPI + '/dashboard/v1';

    constructor(private httpClient: HttpClient) {}

    public get(url: string, options?: HttpOptions, customBaseUrl?: string): Observable<any> {
        return this.request('GET', url, options, customBaseUrl);
    }

    public post(url: string, body?: any, options?: HttpOptions, customBaseUrl?: string): Observable<any> {
        return this.request('POST', url, { body, ...options }, customBaseUrl);
    }

    public put(url: string, body: any, options?: HttpOptions, customBaseUrl?: string): Observable<any> {
        return this.request('PUT', url, { body, ...options }, customBaseUrl);
    }

    public delete(url: string, options?: HttpOptions, customBaseUrl?: string): Observable<any> {
        return this.request('DELETE', url, options, customBaseUrl);
    }

    private request(method: HttpMethod, url: string, options?: HttpOptions, customBaseUrl?: string): Observable<any> {
        return this.httpClient.request(method, `${customBaseUrl || this.baseUrl}/${url}`, options).pipe(
            catchError((err: HttpErrorResponse) => throwError(() => ({ ...err?.error, status: err.status })))
        );
    }
}
