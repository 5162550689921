<div class="layout" [class.dashboard--layout]="isDashboardLayout">
    <app-header
        class="layout-header"
        *ngIf="layoutService.isHeaderAvailable$ | async"
        [isDashboardLayout]="isDashboardLayout"
        [isSignedInUser]="isSignedInUser"
    ></app-header>
    <div
        cdkScrollable
        [class.scroll-disabled]="layoutService.scrollDisabling$ | async"
        class="scrollable-container"
        #scrollableContainer
    >
        <!-- Banner -->
        <!-- TODO: Need to comment this banner after 30 September -->
        <div *ngIf="layoutService.isBannerVisible$ | async" class="layout-banner">
            <div class="layout-banner__close" (click)="layoutService.onCloseBanner()">&times;</div>
            <div class="layout-banner__text">
                <span>Saving $65 today</span>
                for National Wellness Month for your initial appointment
            </div>
        </div>
        <!-- Page Info / Stepper -->
        <app-page-info-block
            class="layout-page-info"
            *ngIf="isPageInfoNeeded$ | async"
            [title]="layoutService.pageTitle$ | async"
            [description]="layoutService.pageDescription$ | async"
            [withBackIcon]="layoutService.isBackArrowVisible$ | async"
        ></app-page-info-block>
        <!--  Body  -->
        <app-body class="layout-body" [isDashboardLayout]="isDashboardLayout"></app-body>
        <!-- Footer -->
        <app-footer
            *ngIf="layoutService.isFooterAvailable$ | async"
            [terms]="terms"
            class="layout-footer"
        ></app-footer>
    </div>
</div>
