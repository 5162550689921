import { animate, style, transition, trigger } from '@angular/animations';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    FormControlDirective,
    FormControlName,
    FormGroupDirective,
    NG_VALUE_ACCESSOR,
    NgControl,
} from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SelectFormFieldOption } from '@patient/app/shared/interfaces/select-form-field-option.interface';
import { ErrorTextPipeModule } from '../../../services/error-text/error-text-pipe.module';

@Component({
    selector: 'app-plain-select',
    templateUrl: './plain-select.component.html',
    styleUrls: ['./plain-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgForOf, NgIf, JsonPipe, ErrorTextPipeModule, MatFormFieldModule, MatRippleModule, MatRadioModule],
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [
                style({ transform: 'translateY(-25%)', opacity: 0 }),
                animate('300ms', style({ transform: 'translateY(0)', opacity: 1 })),
            ]),
        ]),
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PlainSelectComponent,
        },
    ],
    standalone: true,
})
export class PlainSelectComponent implements OnInit, ControlValueAccessor {
    @Input() public options: SelectFormFieldOption[] = [];

    public onChange = (value: number): void => {};
    public onTouched = (): void => {};

    public touched = false;
    public formControlValue: number | undefined;
    public formControl!: FormControl;

    constructor(private injector: Injector) {}

    public ngOnInit(): void {
        this.getFormControl();
    }

    public change(value: any): void {
        this.formControlValue = value !== this.formControlValue ? value : undefined;
        this.onChange(this.formControlValue!);
    }

    public writeValue(value: number): void {
        this.formControlValue = value;
    }

    public registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    public markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    private getFormControl(): void {
        const ngControl = this.injector.get(NgControl);

        if (ngControl instanceof FormControlName) {
            this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
        } else {
            this.formControl = (ngControl as FormControlDirective).form as FormControl;
        }
    }
}
