import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { WebsiteTerms } from '../api/terms/terms-api.interface';
import { LayoutService } from './layout.service';
import { combineLatest, map, Observable } from 'rxjs';
import { PageOverlayService } from '@patient/app/shared/services/page-overlay/page-overlay.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, AfterViewInit {
    @Input() isSignedInUser: boolean = false;
    @Input() terms!: WebsiteTerms | null;

    public isDashboardLayout = false;

    public get isPageInfoNeeded$(): Observable<boolean> {
        return combineLatest([
            this.layoutService.isPageInfoVisible,
            this.pageOverlay.pageOverlayLoading$,
        ]).pipe(map(([info, pageOverlay]) => info && !pageOverlay));
    }

    @ViewChild('scrollableContainer') scrollableContainerRef!: ElementRef;

    constructor(
        public readonly layoutService: LayoutService,
        private readonly cd: ChangeDetectorRef,
        private readonly pageOverlay: PageOverlayService
    ) {}

    public ngOnInit(): void {
        this.layoutService.isDashboardLayout$.subscribe((val) => {
            this.isDashboardLayout = val;
            this.cd.detectChanges();
        });
    }

    public ngAfterViewInit() {
        this.layoutService.setScrollableContainerRef(this.scrollableContainerRef);
    }
}
