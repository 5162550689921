import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

@Injectable({ providedIn: 'root', })
export class ErrorTextService {
    private readonly errors: { [key: string]: string } = {
        required: 'Field is required.',
        email: 'Please enter valid email address.',
        pattern: 'Please enter valid value.',
        not_unique: 'This value is already taken.',
        not_unique_email: 'Email Address is Already Registered. Use Sign in link to access your existing account',
        minlength: 'Min length is {X} symbols.',
        invalidName: 'Please provide a valid name.',
        invalidValue: 'Invalid value entered.',
        invalidZipcode: 'Zipcode is invalid.',
        invalidLanguage: 'Invalid symbols are used.',
        invalidPhoneNumber: 'Phone number is invalid.',
        invalidSsn: 'Ssn is invalid. Correct format XXX-XX-XXXX',
        invalidDateValue: 'Invalid date value.',
        invalidDateFormat: 'Invalid date format.',
        invalidDateRange: 'Invalid date range.',
        invalidMinDate: 'Invalid minimum date value.',
        maxlength: 'Max length is {X} symbols.',
        incorrect: 'Incorrect value.',
        zipCode: 'ZIP invalid. Correct format 00000 or 00000-0000.',
        verificationCodeChecksLimit: 'Wrong code. Please Resend the code and try again.',
        verificationCodeError: 'Wrong code. Please try again.',
        zipCodeNotSupported: 'Zipcode value is incorrect.',
        invalidInsuranceNumber: 'Please provide a valid insurance ID.',
        whitespace: 'Field cannot consist of whitespace only.'
    };

    public getMessage(formControl: FormControl<unknown> | AbstractControl): string {
        const errors = formControl.errors;
        return this.getTextFromErrorsObject(errors);
    }

    public getTextFromErrorsObject(errors: ValidationErrors | null): string {
        return Object.keys(errors || []).map((code) => {
            if (errors && (code === 'maxlength' || code === 'minlength')) {
                return this.errors[code].replace('{X}', errors[code].requiredLength);
            }
            return this.errors[code] || null;
        }).join(' ');
    }
}
