import { Injectable } from '@angular/core';
import { ServiceCodeEnum } from '@patient/app/shared/enums/service-code.enum';
import { IOptionItem } from '@patient/app/shared/interfaces/option-item.interface';
import { BehaviorSubject, finalize, map, Observable, of } from 'rxjs';
import { PharmacySearchParams } from '../../api/intake/intake-api.interface';
import { PharmacyApiService } from '../../api/pharmacy/pharmacy-api.service';
import { UserStateService } from '../user-state/user-state.service';

export interface IPharmacyRecord {
    fullName: string;
    id: number;
    isTrusted: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class PharmacyService {
    public pharmacies$ = new BehaviorSubject<IPharmacyRecord[]>([]);
    public isPharmaciesLoading$ = new BehaviorSubject<boolean>(false);

    public get pharmacies(): IPharmacyRecord[] {
        return this.pharmacies$.getValue();
    }

    constructor(
        private userStateService: UserStateService,
        private pharmacyApiService: PharmacyApiService
    ) {}

    public isScorePharmacyAvailable(leadId: number): boolean {
        const lead = this.userStateService.getLeadById(leadId);

        const isCorrectService = [ServiceCodeEnum.ADHD, ServiceCodeEnum.ANXIETY].includes(
            lead.service.code as ServiceCodeEnum
        );
        const isEvenNumber = !(lead.id & 1);

        return isCorrectService && isEvenNumber;
    }

    public sendIfUntrustedPharmacySelected(
        leadId: number,
        pharmacyFullName: string
    ): Observable<{} | null> {
        const selectedPharmacy = this.pharmacies.find((elem) => elem.fullName === pharmacyFullName);

        if (!this.isScorePharmacyAvailable(leadId) || selectedPharmacy?.isTrusted) {
            return of(null);
        }

        return this.pharmacyApiService.sendUntrustedPharmacySelected(leadId);
    }

    public getPharmacyByLocationParams(
        searchParams: PharmacySearchParams,
        isObjectFormat = false
    ): Observable<string[] | IOptionItem[]> {
        this.isPharmaciesLoading$.next(true);

        return this.pharmacyApiService.searchPharmacy(searchParams).pipe(
            finalize(() => this.isPharmaciesLoading$.next(false)),
            map(({ pharmacies }) => {
                const pharmaciesArray = pharmacies.map((p) => ({
                    fullName:
                        p.StoreName +
                        ', ' +
                        p.City +
                        ', ' +
                        p.Address1 +
                        (p.Address2 ? ', ' + p.Address2 : ''),
                    id: p.PharmacyId,
                    isTrusted: p.isTrusted,
                }));

                this.pharmacies$.next(pharmaciesArray);

                if (isObjectFormat)
                    return pharmaciesArray.map((elem) => ({ id: elem.id, text: elem.fullName }));
                return pharmaciesArray.map((elem) => elem.fullName);
            })
        );
    }
}
