import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class IntercomService {
  private renderer: Renderer2;
  private isConversationCreated: boolean = false;

  private isIntercomConfigExist = !!environment.intercomWorkspaceId;

  private allowedChatUrls = ['dashboard', 'profile'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public checkAllowedForChatRoutes(url: string): boolean {
    return this.allowedChatUrls.some((allowedUrl) => url.includes(allowedUrl));
  }

  public downloadIntercom(): void {
    if (!this.isIntercomConfigExist) return;

    let script = this.renderer.createElement('script');
    script.text = `
            (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === 'function') {
                ic('reattach_activator');
                ic('update', w.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/${environment.intercomWorkspaceId}';
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === 'complete') {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }})();`;

    this.renderer.appendChild(this.document.head, script);
  }

  public intercomSync(id: string, hash: string): void {
    if (!this.isIntercomConfigExist) return;

    //@ts-ignore
    if (this.isConversationCreated) return window.Intercom('update');
    //@ts-ignore
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: `${environment.intercomWorkspaceId}`,
      user_id: id,
      user_hash: hash,
    });

    this.isConversationCreated = true;
  }

  public shutdown(): void {
    if (!this.isIntercomConfigExist) return;

    //@ts-ignore
    window.Intercom('shutdown');
    this.isConversationCreated = false;
  }
}
