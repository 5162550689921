import { Injectable } from '@angular/core';
import {
    EApiPrescriptionStatuses,
    EUDPrescriptionStatuses,
    IApiPrescription,
    PrescriptionMedicationStatusesDivision,
} from '../../interfaces';

@Injectable({ providedIn: 'root' })
export class PrescriptionUtilsService {
    public getPrescriptionStatusClassName(prescription: IApiPrescription): string {
        const udPrescriptionStatus = this.getUDPrescriptionStatus(prescription);
        switch (udPrescriptionStatus) {
            case EUDPrescriptionStatuses.PRESCRIPTION_PREPARATION:
                return 'prescription-preparation';
            case EUDPrescriptionStatuses.CANCELLED:
                return 'canceled';
            case EUDPrescriptionStatuses.SENT_TO_PHARMACY:
                return 'sent-to-pharmacy';
            case EUDPrescriptionStatuses.PHARMACY_PROCESSING:
                return 'pharmacy-processing';
            case EUDPrescriptionStatuses.READY_TO_PICK_UP:
                return 'ready-to-pick-up';
            case EUDPrescriptionStatuses.PARTIAL_FILL_TO_PICK_UP:
                return 'partial-fill-to-pick-up';
            case EUDPrescriptionStatuses.PHARMACY_ISSUE:
                return 'pharmacy-issue';
            case EUDPrescriptionStatuses.COMPLETED:
                return 'completed';
        }
    }

    public getUDPrescriptionStatus(prescription: IApiPrescription): EUDPrescriptionStatuses {
        const isPharmacyVerified =
            prescription.status === EApiPrescriptionStatuses.PHARMACY_VERIFIED;

        const status =
            isPharmacyVerified && prescription.medicationStatus
                ? prescription.medicationStatus
                : prescription.status;

        const value = Object.entries(PrescriptionMedicationStatusesDivision).find(([, value]) =>
            value?.includes(status)
        );
        return value
            ? (value[0] as EUDPrescriptionStatuses)
            : EUDPrescriptionStatuses.PRESCRIPTION_PREPARATION;
    }
}
