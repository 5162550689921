import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { GoogleSymbolIconComponent } from '@shared/components';

@Component({
    selector: 'app-document-example-list',
    templateUrl: './document-example-list.component.html',
    styleUrls: ['./document-example-list.component.scss'],
    standalone: true,
    imports: [MatTooltipModule, GoogleSymbolIconComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentExampleListComponent {
    @Input() isExpanded = false
}
