import { IApiLeadServiceLead } from './lead-service';
import { IUTMPropsValues } from '../services/utm-props/utm-props.interface';

export enum EApiUserServiceVerificationCodeType {
    LOGIN = 'LOGIN',
    ACCOUNT_VERIFICATION = 'ACCOUNT_VERIFICATION',
}

export enum EApiUserServiceGender {
    FEMALE = 0,
    MALE = 1,
    OTHER = 2,
}

export interface IApiUserServiceUser {
    dosespotId: number;
    dateOfBirth?: string;
    email: string;
    firstName?: string;
    gender?: EApiUserServiceGender;
    id: number;
    lastName: string;
    phone: string;
    phoneVerified: boolean;
    state?: {
        id: number;
        code: string;
        name: string;
        timezone: string;
        medicineDelivery: boolean;
    };
    zip?: string;
    surveySparrowFakeEmail: string;
    isOnboardingFinished: boolean;

    lastContactId: number;
    lastLeadId: number;
    intercomUserId?: string;
    intercomChatHash?: string;
    allowFreeWeightLossFollowUp?: boolean;
}

export interface IApiUserServiceVerificationCode {
    id: number;
    validTill: Date;
    resendToken: string;
    checksLeft: number;
    ttl: number;
    resendTimeout: number;
}

export interface IApiUserServiceRequestAuthorizationParams {
    login: string;
}

export interface IApiUserServiceRequestAuthorizationResponse {
    success: boolean;
    type: EApiUserServiceVerificationCodeType;
    verificationCode: IApiUserServiceVerificationCode;
}

export interface IApiUserServiceSignUpParams {
    firstName: string;
    lastName: string;
    gender: EApiUserServiceGender;
    dateOfBirth: string;
    email: string;
    phone: string;
    zip: string;
    receiveCallsAndMessagesAgreementStatus: boolean;
    setOfAgreementsStatus: boolean;
    websiteCode: string;
    gClientId?: string;
    utm?: IUTMPropsValues;
}

export interface IApiUserServiceResendVerificationCodeParams {
    resendToken: string;
}

export type IApiUserServiceResendVerificationCodeResponse = IApiUserServiceVerificationCode;

export interface IApiUserServiceLoginParams {
    login: string;
    code: string;
    type: EApiUserServiceVerificationCodeType;
    checksLeft?: number;
    serviceCode?: string;
    websiteCode: string;
    utm?: IUTMPropsValues;
    gClientId?: string;
    agreementStatus?: boolean;
}

export interface IApiUserServiceLoginAuth {
    token: string;
    refreshToken: string;
    expiresIn: number;
    userId: number;
}

export interface IApiUserServiceLoginResponse {
    user: IApiUserServiceUser;
    auth: IApiUserServiceLoginAuth;
    isReturningServicePlan: boolean;
}

export type IApiUserServiceRefreshTokenResponse = IApiUserServiceLoginAuth;

export interface IApiUserServiceUpdateParams {
    email?: string;
    firstName?: string;
    lastName?: string;
    zip?: string;
    dateOfBirth?: string;
    gender?: EApiUserServiceGender;
    insuranceId?: number;
}

export interface IUserProfileForm {
    email?: string;
    firstName?: string;
    lastName?: string;
    zip?: string;
    dateOfBirth?: string;
    gender?: EApiUserServiceGender;
    phone?: string;
}

export interface IApiUserServiceUpdateResponse {
    user: IApiUserServiceUser;
}

export interface IApiUserServiceGetDataResponse {
    user: IApiUserServiceUser;
    leads: IApiLeadServiceLead[];
}

export interface ICreateForWebRequest {
    email?: string | null;
    phone?: string | null;
    dateOfBirth: string;
}
