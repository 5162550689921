import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '../rest-api.service';
import { WebsiteTerms } from './terms-api.interface';

@Injectable({ providedIn: 'root' })
export class TermsApiService {

    constructor(private restApiService: RestApiService) { }

    public getWebsiteTerms(websiteCode: string): Observable<WebsiteTerms> {
        return this.restApiService.get(`terms`, { params: { websiteCode } });
    }
}
