<ul class="header-burger-menu" [class.inside-header]="insideHeader">
    <li class="header-burger-menu__item">
        <a
            routerLink="/dashboard/appointments"
            routerLinkActive="active"
            (click)="navigate.emit()"
            data-test-id="Appointments Page"
            >Appointments</a
        >
    </li>
    <li class="header-burger-menu__item">
        <a
            routerLink="/dashboard/prescriptions"
            routerLinkActive="active"
            (click)="navigate.emit()"
            data-test-id="Prescriptions Page"
            >Prescriptions</a
        >
    </li>
    <li class="header-burger-menu__item">
        <a
            routerLink="/dashboard/documents"
            routerLinkActive="active"
            (click)="navigate.emit()"
            data-test-id="Documents Page"
            >Documents</a
        >
    </li>
</ul>
