import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProgressBarService {
    public progress$ = new BehaviorSubject<number>(0); // from 0 to 100;
    public isVisible$ = new BehaviorSubject<boolean>(false);

    public show(): void {
        this.isVisible$.next(true);
    }

    public update(progress: number): void {
        if (progress > 100 || progress < 0) return;
        this.progress$.next(progress);
    }

    public clear(): void {
        this.hide();
        this.progress$.next(0);
    }

    public hide(): void {
        this.isVisible$.next(false);
    }
}
