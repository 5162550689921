import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { TermsApiService } from '../../api/terms/terms-api.service';
import { WebsiteTerms } from '../../api/terms/terms-api.interface';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnvironmentDataService {
  public readonly websiteCode: string;
  public readonly clinicName: string;
  public websiteTerms = new BehaviorSubject<WebsiteTerms | null>(null);

  constructor(private termsService: TermsApiService) {
    const { websiteCode: websiteCodes, clinicName: clinicNames } =
      environment as Extract<
        typeof environment,
        'websiteCode' | 'favicon' | 'clinicName'
      >;
    const hostname = location.hostname;

    this.websiteCode = websiteCodes[hostname] || websiteCodes['default'];
    this.clinicName = clinicNames[this.websiteCode] || clinicNames['default'];

    this.requestWebsiteTerms();
  }

  private requestWebsiteTerms(): void {
    this.termsService
      .getWebsiteTerms(this.websiteCode)
      .pipe(take(1))
      .subscribe((terms) => this.websiteTerms.next(terms));
  }
}
