import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { LayoutService } from '@patient/app/layout/layout.service';
import { IntercomService } from '@patient/app/shared/modules/intercom/intercom.service';
import { PageOverlayService } from '@patient/app/shared/services/page-overlay/page-overlay.service';
import { distinctUntilChanged } from 'rxjs';
import { EnvironmentDataService, ParametersService, UserStateService } from './services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    public isRouteReady = false;
    public isPageLoading$ = this.pageOverlayService.pageOverlayLoading$.pipe(
        distinctUntilChanged()
    );

    public get isSignedInUser(): boolean {
        return !!this.authService.user?.dateOfBirth;
    }

    constructor(
        public envDataService: EnvironmentDataService,
        public authService: UserStateService,
        public pageOverlayService: PageOverlayService,
        private router: Router,
        private appParametersServices: ParametersService,
        private intercomService: IntercomService,
        private layoutService: LayoutService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.appParametersServices.initAppParameters();
        this.handleNavigationLoading();
        this.intercomService.downloadIntercom();
    }

    private handleNavigationLoading(): void {
        this.router.events.subscribe((e: RouterEvent) => {
            this.setNavigationLoading(e);
            this.setLayoutVisibility(e)
        });
    }

    private setNavigationLoading(e: RouterEvent): void {
        this.cdRef.markForCheck();

        if (e instanceof NavigationStart) this.isRouteReady = false;
        if (e instanceof NavigationEnd || e instanceof NavigationCancel) {
            this.isRouteReady = true;
        }
    }

    private setLayoutVisibility(e: RouterEvent): void {
        if (!(e instanceof NavigationEnd)) return;

        const isRouteWithHeader = (e.url !== '/signup') &&  (e.url !== '/signin')

        if (isRouteWithHeader) {
            this.layoutService.isHeaderAvailable = true;
            this.layoutService.isFooterAvailable = true;
        } else {
            this.layoutService.isHeaderAvailable = false;
            this.layoutService.isFooterAvailable = false;
        }
    }
}
