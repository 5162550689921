import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageOverlayComponent } from './page-overlay.component';
import { PageOverlayService } from '@patient/app/shared/services/page-overlay/page-overlay.service';

@NgModule({
    declarations: [PageOverlayComponent],
    imports: [CommonModule],
    exports: [PageOverlayComponent],
    providers: [PageOverlayService],
})
export class PageOverlayModule {}
