import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum EIconWeight {
    THIN = 'thin',
    LIGHT = 'light',
    MEDIUM = 'medium',
    THICK = 'thick',
}

@Component({
    selector: 'mv-google-symbol-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './google-symbol-icon.component.html',
    styleUrls: ['./google-symbol-icon.component.scss'],
    host: { class: 'mv-google-symbol-icon' },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleSymbolIconComponent {
    @Input() size = 24;
    @Input() weight: 200 | 300 | 400 | 700 = 400;

    public get class(): EIconWeight {
        if (this.weight === 200) {
            return EIconWeight.THIN;
        }

        if (this.weight === 300) {
            return EIconWeight.LIGHT;
        }

        if (this.weight === 400) {
            return EIconWeight.MEDIUM;
        }

        if (this.weight === 700) {
            return EIconWeight.THICK;
        }
    }
}
