export interface ISnackbarComponentData {
    message: string;
    type: ESnackbarComponentTypes;
}

export enum ESnackbarComponentTypes {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
}
