import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntercomService } from '@patient/app/shared/modules/intercom/intercom.service';
import { ProgressBarService } from '@patient/app/shared/services/progress-bar/progress-bar.service';
import { AuthTokenService, UserStateService } from '@patient/app/services';
import { LayoutService } from '../layout.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Input() isSignedInUser: boolean = false;
    @Input() isDashboardLayout: boolean = false;

    public isBurgerMenuOpened = false;
    public isAvatarMenuOpened = false;

    public get isLogoCentered(): boolean {
        if (!this.isSignedInUser) return true;

        return (
            !this.layoutService.isMenuAvailable$.getValue() &&
            !this.layoutService.isAvatarAvailable$.getValue()
        );
    }

    constructor(
        public userStateService: UserStateService,
        public layoutService: LayoutService,
        public progressBarService: ProgressBarService,
        private appAuthTokenService: AuthTokenService,
        private intercomService: IntercomService
    ) {}

    public onBurgerClick(): void {
        this.isBurgerMenuOpened = !this.isBurgerMenuOpened;
        this.isAvatarMenuOpened = false;
    }

    public onAvatarIconClick(): void {
        this.isAvatarMenuOpened = !this.isAvatarMenuOpened;
        this.isBurgerMenuOpened = false;
    }

    public onLogoutClick(): void {
        this.intercomService.shutdown();
        this.appAuthTokenService.removeAuthTokenData();
        window.location.href = '/signin';
        this.isAvatarMenuOpened = false;
    }

    public navigateFromMenu(): void {
        this.isBurgerMenuOpened = false;
        this.isAvatarMenuOpened = false;
    }

    public closeMenus(): void {
        this.isAvatarMenuOpened = false;
        this.isBurgerMenuOpened = false;
    }
}
