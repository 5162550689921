import { ErrorTextPipe } from './error-text.pipe';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [ErrorTextPipe],
    imports: [CommonModule],
    exports: [ErrorTextPipe],
})
export class ErrorTextPipeModule {}
