import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageTitleEnum } from './interfaces/page-title.enum';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
        path: 'signin',
        loadChildren: () => import('./pages/sign-in/sign-in.module'),
        title: PageTitleEnum.SIGN_IN,
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/sign-up/sign-up.module'),
        title: PageTitleEnum.SIGN_UP,
    },
    {
        path: 'start',
        loadChildren: () => import('./pages/start/start.module'),
        title: PageTitleEnum.START,
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module'),
        title: PageTitleEnum.DASHBOARD,
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/personal-data/personal-data.module'),
        title: PageTitleEnum.PROFILE,
    },
    {
        path: 'appointment',
        loadChildren: () => import('./pages/appointment-page/appointment-page.module'),
        title: PageTitleEnum.BOOKING,
    },
    {
        path: 'manage-appointment/:id',
        loadChildren: () => import('./pages/manage-appointment/manage-appointment.module'),
        title: PageTitleEnum.MANAGE_APPOINTMENT,
    },
    {
        path: 'cancel-appointment/:id',
        loadChildren: () => import('./pages/cancel-appointment/cancel-appointment.module'),
        title: PageTitleEnum.CANCEL_APPOINTMENT,
    },
    {
        path: 'change-pharmacy/:id',
        loadChildren: () => import('./pages/change-pharmacy/change-pharmacy.module'),
    },
    {
        path: 'payment',
        loadChildren: () => import('./pages/payment/payment.module'),
        title: PageTitleEnum.PAYMENT,
    },
    {
        path: 'intake/:uuid',
        loadChildren: () => import('./pages/intake-form/intake-form.module'),
        title: PageTitleEnum.INTAKE,
    },
    {
        path: 'thank-you',
        loadChildren: () => import('./pages/thank-you-page/thank-you-page.module'),
        title: PageTitleEnum.INTAKE_THANK_YOU,
    },
    {
        path: 'update-zip',
        loadChildren: () => import('./pages/update-zip/update-zip.module'),
        title: PageTitleEnum.UPDATE_ZIP,
    },
    {
        path: 'video-call/:leadId',
        loadChildren: () => import('./pages/video-call/video-call.module'),
        title: PageTitleEnum.VIDEO_CALL,
    },
    {
        path: '**',
        loadChildren: () => import('./pages/not-found/not-found.module'),
        title: PageTitleEnum.DEFAULT,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
