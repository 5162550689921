export interface IDashboardPageTask {
    type: EDashboardPageTaskType;
    service: {
        code: string;
        name: string;
    };
    peerId?: string;
    applicationUUID?: string;
    leadId: number;
    prescriptionDosespotIds: number[];
}

export enum EDashboardPageTaskType {
    TIME = 'time',
    PAYMENT = 'payment',
    INTAKE = 'intake',
    VISIT = 'visit',
    FOLLOW_UP_VISIT = 'follow_up_visit',
    NONE = 'none',
    SECOND_PAYMENT = 'second_payment',
    PRESCRIPTIONS = 'prescriptions',
}
