import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { SnackbarComponent } from '@patient/app/shared/components/snackbar/snackbar.component';
import { ESnackbarComponentTypes } from '@patient/app/shared/interfaces/snackbar.interface';

const DEFAULT_DURATION_TIME = 9000;
const DEFAULT_ERROR_DURATION_TIME = 5000;
const DEFAULT_ERROR_MESSAGE = `
    We were not able to get information from the server.
    Please check your connection and refresh the page to try again.
`;

@Injectable()
export class SnackbarService {
    constructor(private snackBar: MatSnackBar) {}

    public showSnackSuccess(
        message: string,
        duration: number = DEFAULT_DURATION_TIME
    ): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(message, duration, ESnackbarComponentTypes.SUCCESS);
    }

    public showSnackError(
        message: string = DEFAULT_ERROR_MESSAGE,
        duration: number = DEFAULT_ERROR_DURATION_TIME
    ): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(message, duration, ESnackbarComponentTypes.ERROR);
    }

    public showSnackInfo(
        message: string,
        duration: number = DEFAULT_ERROR_DURATION_TIME
    ): MatSnackBarRef<SnackbarComponent> {
        return this.openSnackBar(message, duration, ESnackbarComponentTypes.INFO);
    }

    private openSnackBar(
        message: string,
        duration: number,
        type: ESnackbarComponentTypes
    ): MatSnackBarRef<SnackbarComponent> {
        return this.snackBar.openFromComponent(SnackbarComponent, {
            duration,
            data: { message, type },
            panelClass: 'custom-snackbar',
        });
    }
}
