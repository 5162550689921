import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PageOverlayService {
    private _pageOverlayLoading$ = new BehaviorSubject<boolean>(false);

    public set pageOverlayLoading(value: boolean) {
        this._pageOverlayLoading$.next(value);
    }

    public get pageOverlayLoading$(): Observable<boolean> {
        return this._pageOverlayLoading$.asObservable();
    }

    public show(): void {
        this.pageOverlayLoading = true;
    }

    public hide(): void {
        this.pageOverlayLoading = false;
    }
}
