import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
    EmailValidator,
    NameValidator,
    PhoneValidator,
    ZipcodeValidator,
} from '@patient/app/shared/validators';
import { IApiUserServiceUser, IUserProfileForm } from '../../interfaces';
import { Form } from '@patient/app/shared/types/interface-to-form.type';

@Injectable()
export class PersonalDataFormService {
    constructor(private formBuilder: UntypedFormBuilder) {}

    public getPersonalDataFormGroup(user?: IApiUserServiceUser): FormGroup<Form<IUserProfileForm>> {
        let { phone, firstName, lastName, email, zip, dateOfBirth, gender } = user || {};

        const nameValidators = [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(255),
            NameValidator(),
        ];

        return this.formBuilder.group({
            firstName: [firstName, nameValidators],
            lastName: [lastName, nameValidators],
            email: [email, [EmailValidator(), Validators.required]],
            zip: [zip, [ZipcodeValidator(), Validators.required]],
            phone: [
                {
                    value: phone?.slice(2),
                },
                [Validators.required, PhoneValidator()],
            ],
            dateOfBirth: [dateOfBirth, Validators.required],
            gender: [gender, Validators.required],
        });
    }
}
