export const getDeviceInfo = () => {
    const navigator = window.navigator as any;

    return {
        platform: navigator['userAgentData']?.platform ?? navigator['platform'] ?? null,
        isMobile: navigator['userAgentData']?.mobile ?? null,
        browserType: navigator['userAgentData']?.brands[0]?.brand ?? null,
        browserVersion: navigator['userAgentData']?.brands[0]?.version ?? null,
        effectiveType: navigator['connection']?.effectiveType ?? null,
        downlink: navigator['connection']?.downlink ?? null,
        rtt: navigator['connection']?.rtt ?? null,
        deviceMemory: navigator['deviceMemory'] ?? null,
        vendor: navigator['vendor'] ?? null,
        cookieEnabled: navigator['cookieEnabled'] ?? null,
        userAgent: navigator['userAgent'] ?? null,
    };
};
