import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import {
    ESnackbarComponentTypes,
    ISnackbarComponentData,
} from '@patient/app/shared/interfaces/snackbar.interface';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatIconModule, MatButtonModule, NgClass],
})
export class SnackbarComponent {
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarComponentData,
        private snackBarRef: MatSnackBarRef<SnackbarComponent>
    ) {}

    public get icon(): string {
        switch (this.data.type) {
            case ESnackbarComponentTypes.SUCCESS:
                return 'check_circle_outline';
            case ESnackbarComponentTypes.ERROR:
                return 'error_outline';
            case ESnackbarComponentTypes.INFO:
                return 'info_outline';
        }
    }

    public closeSnackBar(): void {
        this.snackBarRef.dismiss();
    }
}
