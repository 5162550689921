import { Injectable } from '@angular/core';
import { IApiUserServiceLoginAuth, IApiUserServiceRefreshTokenResponse } from '../../interfaces';
import { ELocalStorageKeys } from '../../interfaces/app-parameters';

@Injectable({ providedIn: 'root' })
export class AuthTokenService {
    public setAuthTokenData(
        data: IApiUserServiceLoginAuth | IApiUserServiceRefreshTokenResponse
    ): void {
        localStorage.setItem(ELocalStorageKeys.API_AUTH_TOKEN, data.token);
        localStorage.setItem(ELocalStorageKeys.API_AUTH_REFRESH_TOKEN, data.refreshToken);
        localStorage.setItem(ELocalStorageKeys.I_WAS_AUTHORIZED, (+new Date()).toString());
        if (!data.expiresIn) return;
        const exp = (+new Date() + data.expiresIn * 1000).toString();
        localStorage.setItem(ELocalStorageKeys.API_AUTH_TOKEN_EXP, exp);
    }

    public removeAuthTokenData(): void {
        localStorage.removeItem(ELocalStorageKeys.API_AUTH_TOKEN);
        localStorage.removeItem(ELocalStorageKeys.API_AUTH_TOKEN_EXP);
        localStorage.removeItem(ELocalStorageKeys.API_AUTH_REFRESH_TOKEN);
    }
}
