import { Injectable, Inject, ErrorHandler } from '@angular/core'
import { captureException } from '@sentry/browser'
import { SentryModuleOptions, OPTIONS } from './tokens'

@Injectable({ providedIn: 'root'})
export class SentryErrorHandler implements ErrorHandler {
    public constructor(@Inject(OPTIONS) private readonly options: SentryModuleOptions) {}

    public handleError(error: any): void {
        if (this.options.enabled) {
            captureException(error.originalError || error);
        }
    }
}
