import { Injectable } from '@angular/core';
import { WebViewControllersEnum } from './web-view.enum';

declare const Logout: any;
declare const RefreshToken: any;

@Injectable({ providedIn: 'root' })
export class WebViewService {
    public postMessage(webViewController: WebViewControllersEnum): void {
        try {
            switch (webViewController) {
                case WebViewControllersEnum.LOGOUT:
                    Logout.postMessage(true);
                    break;
                case WebViewControllersEnum.REFRESH_TOKEN:
                    RefreshToken.postMessage(true);
                    break;
                default:
                    break;
            }
        } catch {
            console.log(`Web View Controller (${webViewController}) isn't exist`);
        }
    }
}
