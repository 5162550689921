import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EHeaderNavigation } from './layout.enums';
import { ELocalStorageKeys } from '../interfaces/app-parameters';

@Injectable({ providedIn: 'root' })
export class LayoutService {
    private scrollableContainerRef!: ElementRef;
    private _scrollDisabling$ = new BehaviorSubject<boolean>(false);
    private _isDashboardLayout$ = new BehaviorSubject<boolean>(false);
    private _isBannerVisible$ = new BehaviorSubject<boolean>(false);
    private _isHeaderAvailable$ = new BehaviorSubject<boolean>(true);

    private _isPageInfoVisible = new BehaviorSubject<boolean>(false);
    private _pageTitle = new BehaviorSubject<string>('');
    private _pageDescription = new BehaviorSubject<string>('');
    private _arrowVisible = new BehaviorSubject<boolean>(false);
    private _isFooterAvailable$ = new BehaviorSubject<boolean>(true);

    public isMenuAvailable$ = new BehaviorSubject<boolean>(true);
    public isAvatarAvailable$ = new BehaviorSubject<boolean>(true);

    public showPageInfo(config: {
        title: string;
        description: string;
        hideBackArrow?: boolean;
        banner?: boolean;
    }): void {
        this._pageTitle.next(config.title);
        this._pageDescription.next(config.description);
        this._arrowVisible.next(!config.hideBackArrow);
        this._isPageInfoVisible.next(true);

        this.checkNeededBannerOrNot(config.banner ?? null);
    }

    public hidePageInfo(config?: { keepBannerVisible?: boolean }): void {
        // reset
        this._pageTitle.next('');
        this._pageDescription.next('');
        this._arrowVisible.next(true);
        // hide
        this._isPageInfoVisible.next(false);

        this.checkNeededBannerOrNot(config?.keepBannerVisible ?? null);
    }

    public showHeaderNavigation(config: EHeaderNavigation[] = []): void {
        const { MENU, AVATAR } = EHeaderNavigation;

        config.includes(MENU) && this.isMenuAvailable$.next(true);
        config.includes(AVATAR) && this.isAvatarAvailable$.next(true);
    }

    public hideHeaderNavigation(config: EHeaderNavigation[] = []): void {
        const { MENU, AVATAR } = EHeaderNavigation;

        config.includes(MENU) && this.isMenuAvailable$.next(false);
        config.includes(AVATAR) && this.isAvatarAvailable$.next(false);
    }

    public get isPageInfoVisible(): Observable<boolean> {
        return this._isPageInfoVisible.asObservable();
    }

    public get pageTitle(): string {
        return this._pageTitle.getValue();
    }

    public get pageTitle$(): Observable<string> {
        return this._pageTitle.asObservable();
    }

    public get pageDescription$(): Observable<string> {
        return this._pageDescription.asObservable();
    }

    public get isBackArrowVisible$(): Observable<boolean> {
        return this._arrowVisible.asObservable();
    }

    public get scrollDisabling$(): Observable<boolean> {
        return this._scrollDisabling$.asObservable();
    }

    public get isDashboardLayout$(): Observable<boolean> {
        return this._isDashboardLayout$.asObservable();
    }

    public get isBannerVisible$(): Observable<boolean> {
        return this._isBannerVisible$.asObservable();
    }

    public get isHeaderAvailable$(): Observable<boolean> {
        return this._isHeaderAvailable$.asObservable();
    }

    public set isHeaderAvailable(value: boolean) {
        this._isHeaderAvailable$.next(value);
    }

    public get isFooterAvailable$(): Observable<boolean> {
        return this._isFooterAvailable$.asObservable();
    }

    public set isFooterAvailable(value: boolean) {
        this._isFooterAvailable$.next(value);
    }

    public setScrollableContainerRef(el: ElementRef) {
        this.scrollableContainerRef = el;
    }

    public scrollToTop(
        config: Partial<{ behavior: 'smooth' | 'auto'; top: number }> = {
            top: 0,
            behavior: 'smooth',
        }
    ): void {
        setTimeout(() => {
            this.scrollableContainerRef.nativeElement.scrollTo({
                top: config.top || 0,
                behavior: config.behavior || 'smooth',
            });
        });
    }

    public initDashboardLayout(): void {
        this._isDashboardLayout$.next(true);
    }

    public resetLayout(): void {
        this._isDashboardLayout$.next(false);
    }

    public checkNeededBannerOrNot(visible: boolean | null): void {
        const today = new Date();
        const endDate = new Date(2023, 9, 1);
        const isClosedByUser = !!localStorage.getItem(ELocalStorageKeys.DISCOUNT_IS_CLOSED_BY_USER);

        this._isBannerVisible$.next(Boolean(visible && today < endDate && !isClosedByUser));
    }

    public onCloseBanner(): void {
        this._isBannerVisible$.next(false);
        localStorage.setItem(ELocalStorageKeys.DISCOUNT_IS_CLOSED_BY_USER, 'true');
    }
}
