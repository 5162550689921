<ul class="documents-list" [class.expanded]="isExpanded">
    <li class="list-item list-item">
        <mv-google-symbol-icon [size]="20">vaccines</mv-google-symbol-icon>
        <span class="list-item__text">Labs done in the past six months</span>
    </li>
    <li class="list-list-item list-item">
        <mv-google-symbol-icon [size]="20">note_alt</mv-google-symbol-icon>
        <span class="list-item__text">Medical records from a previous provider</span>
    </li>
    <li class="list-item list-item">
        <mv-google-symbol-icon [size]="20">workspace_premium</mv-google-symbol-icon>
        <span class="list-item__text">Substance clearance letter</span>
        <span
            class="info-icon"
            matTooltip="Certain medications like suboxone, buprenorphine, and naloxone require a clearance letter in order to ensure a safe treatment plan."
            matTooltipPosition="below"
            matTooltipClass="tooltip"
        >i</span
        >
    </li>
    <li class="list-item list-item">
        <mv-google-symbol-icon [size]="20">monitor_heart</mv-google-symbol-icon>
        <span class="list-item__text">Blood pressure logs</span>
    </li>
</ul>
