import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-avatar-menu',
    templateUrl: './avatar-menu.component.html',
    styleUrls: ['./avatar-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarMenuComponent {
    @Output() logout = new EventEmitter<void>();
    @Output() editProfile = new EventEmitter<void>();
}
