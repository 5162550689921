import { Injectable } from '@angular/core';
import { GoogleAnalyticOptions } from '@patient/app/shared/modules/google/google.module';
import { EventTypes, EventOptions } from './google-analytic.utils';

declare const window: any;

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticService {
    private appId: string | undefined;
    private config: GoogleAnalyticOptions | undefined;

    public initGoogleAnalytic(config: GoogleAnalyticOptions): void {
        console.log('SET GOOGLE APPLICATION ID => ', config.applicationId);
        this.appId = config.applicationId;
        this.config = config;

        if (!this.appId) return;

        this.loadScript();
    }

    private loadScript(): Promise<void> {
        return new Promise((resolve, reject) => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            const firstScript = document.getElementsByTagName('script')[0];
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://www.googletagmanager.com/gtm.js?id=${this.appId}&l=dataLayer`;
            script.async = true;
            if (firstScript) {
                firstScript.parentNode?.insertBefore(script, firstScript);
            }
            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = `https://www.googletagmanager.com/ns.html?id=${this.appId}`;
            iframe.height = '0';
            iframe.width = '0';
            iframe.style.cssText = 'display:none;visibility:hidden';
            noscript.appendChild(iframe);
            document.getElementsByTagName('body')[0].appendChild(noscript);
            script.onload = () => resolve();
            script.onerror = (err) => reject(err);
        });
    }

    public emitEvent(event_name: EventTypes, options: EventOptions): void {
        const event = {
            event: event_name,
            page_location: location.href,
            ...options,
        };

        if (this.config?.isAvailableInConsole) {
            console.table(event);
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }
}
