<mat-radio-group
    [value]="formControlValue"
    (change)="change($event.value)"
    color="primary"
    class="form-container"
>
    <mat-radio-button
        *ngFor="let option of options"
        class="option"
        [value]="option.value"
        [class.selected]="formControlValue === option.value"
        [attr.data-test-id]="'radio-button-' + option.label"
    >
        {{ option.label }}
    </mat-radio-button>
    <!--    <mat-error *ngIf="formControl.errors && touched" [@enterAnimation] class="error-message">-->
    <!--        {{ formControl.errors | errorText }}-->
    <!--    </mat-error>-->
</mat-radio-group>
