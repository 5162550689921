import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-burger-menu',
    templateUrl: './burger-menu.component.html',
    styleUrls: ['./burger-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurgerMenuComponent {
    @Input() insideHeader: boolean = false;
    @Output() navigate = new EventEmitter<void>();
}
